/** @jsxImportSource @emotion/react */
import { useState } from "react";

import { GoalStatus } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { GoalCardProps } from "..";
import calendarImageUrl from "../../graphics/calendar.png";
import { BaseGoalCard } from "../base-goal-card";
import { HourBasedGoalModal } from "../hour-based-goal-modal";
import { useCommonBaseGoalCardProps, useGoalActivation } from "../lib";

export function WeekendShiftGoalCard({ goal, cardContext }: GoalCardProps) {
  const { formatMessage, formatDate } = useFormatters();
  const [imInterestedModalOpen, setImInterestedModalOpen] = useState(false);
  const { activateGoalAndTrack, activationLoading } = useGoalActivation();
  const commonBaseGoalCardProps = useCommonBaseGoalCardProps({
    goal,
    cardContext,
    pathToTrack: "/goal-track/weekend-shift",
  });

  const buttonLabel = formatMessage({
    defaultMessage: "I'm interested",
    description: "Goal card > weekend shift > button label",
  });

  const handlePrimaryButtonClick = async () => {
    if (!goal.activatedAt) {
      await activateGoalAndTrack(goal);
      setImInterestedModalOpen(true);
    }
  };

  const detailText = (() => {
    switch (goal.status) {
      case GoalStatus.Achieved:
        return formatDate(goal.achievedAt, {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      case GoalStatus.Available:
      case GoalStatus.Locked:
        return goal.activatedAt
          ? formatMessage({
              defaultMessage:
                "You're signed up for weekend shift opportunities and will earn points if you complete one",
              description: "Goal card > weekend shift > activated details text",
            })
          : formatMessage({
              defaultMessage:
                "Sign up for weekend shift opportunities and earn points if you complete one",
              description: "Goal card > weekend shift > details text",
            });
      default:
        return undefined;
    }
  })();

  const modalTitle = formatMessage({
    defaultMessage: "Got it!",
    description: "Goal card > weekend shift modal > title",
  });

  const modalDescription = formatMessage({
    defaultMessage:
      "Your coordinator will contact you when a weekend shift becomes available",
    description: "Goal card > weekend shift modal > description",
  });

  const modalButtonLabel = formatMessage({
    defaultMessage: "Got it!",
    description: "Goal card > weekend shift modal > close button",
  });

  return (
    <>
      <HourBasedGoalModal
        open={imInterestedModalOpen}
        onClose={() => setImInterestedModalOpen(false)}
        title={modalTitle}
        description={modalDescription}
        buttonLabel={modalButtonLabel}
      />

      <BaseGoalCard
        {...commonBaseGoalCardProps}
        icon={<img src={calendarImageUrl} alt="calendar" />}
        detailText={detailText}
        primaryButtonProps={
          goal.status === GoalStatus.Available
            ? {
                label: buttonLabel,
                disabled: Boolean(goal.activatedAt),
                onClick: handlePrimaryButtonClick,
                loading: activationLoading,
              }
            : undefined
        }
      />
    </>
  );
}
