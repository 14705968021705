/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { useRedemptionPageData } from "../points-summary/hooks";
import { GiftCardList } from "../points-summary/redeem/catalog-giftcard-redemption-info/gift-card-list";

export function RedeemGiftCardsPage() {
  const { formatMessage } = useFormatters();
  const pageDataQuery = useRedemptionPageData();

  const content = (() => {
    if (pageDataQuery.error) {
      return <ErrorPage />;
    }

    if (!pageDataQuery.data) {
      return <PageLoadingState />;
    }

    return (
      <>
        <Typography variant="h3">
          {formatMessage({
            defaultMessage: "Redeem gift cards",
            description: "Redeem gift cards page > header",
          })}
        </Typography>
        <Typography
          variant="body"
          css={(appTheme: AppTheme) => css`
            margin-bottom: ${appTheme.spacing(2)};
            color: ${appTheme.palette.grey[800]};
          `}
        >
          {formatMessage({
            defaultMessage:
              "Choose a gift card that's right for you and turn your points into something special!",
            description: "Redeem gift cards page > subtitle",
          })}
        </Typography>
        <GiftCardList
          isLocked={pageDataQuery.data.isLocked}
          pointsAvailableToRedeem={pageDataQuery.data.pointsAvailableToRedeem}
          pointsPerDollar={pageDataQuery.data.pointsPerDollar}
          minimumPointsToRedeem={pageDataQuery.data.minimumPointsToRedeem}
          email={pageDataQuery.data.email}
          phoneNumber={pageDataQuery.data.phoneNumber}
        />
      </>
    );
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        height: 100%;
        overflow-y: scroll;
        background-color: white;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <SubPageHeader
        analyticsPageName="Redeem gift card"
        backTo="rewards"
        color="white"
      />

      <PageContentContainer>
        <ScrollToTopOnMount />
        {content}
      </PageContentContainer>
    </div>
  );
}
