/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Outlet } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SubPageNavigationTabs } from "../../../shared/navigation-tabs";
import { PointsSummaryHeader } from "./header";
import { useRedemptionPageData } from "./hooks";
import { PointsProgress } from "./points-progress";

export function PointsSummaryPage(): JSX.Element {
  const { formatMessage } = useFormatters();

  const pageDataQuery = useRedemptionPageData();

  if (pageDataQuery.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Points summary page > data load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!pageDataQuery.data) {
    return <PageLoadingState />;
  }

  // We support only one redemption method for now. So we can just get the first one.
  const redemptionMethods = pageDataQuery.data.redemptionMethods;

  // header only currently shows for payroll redemptions
  const showHeader = redemptionMethods.includes(RedemptionMethod.Payroll);

  return (
    <div>
      {showHeader && <PointsSummaryHeader />}
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          flex-direction: column;
          background-color: ${appTheme.palette.background.paper};
          & > :last-child {
            flex-grow: 1;
          }
          min-height: 100vh;
        `}
      >
        <PointsProgress
          email={pageDataQuery.data.email}
          pointsAvailableToRedeem={pageDataQuery.data.pointsAvailableToRedeem}
          minimumPointsToRedeem={pageDataQuery.data.minimumPointsToRedeem}
          pointsPerDollar={pageDataQuery.data.pointsPerDollar}
          redemptionMethods={redemptionMethods}
          phoneNumber={pageDataQuery.data.phoneNumber}
        />
        <div
          css={(appTheme: AppTheme) => css`
            background-color: ${appTheme.palette.primary.main};
          `}
        >
          <div
            css={(appTheme: AppTheme) => css`
              max-width: ${appTheme.maxContentWidth}px;
              background-color: ${appTheme.palette.primary.main};
              margin: 0 auto;
              ${appTheme.breakpoints.up("lg")} {
                padding-top: ${appTheme.spacing(3)};
              }
            `}
          >
            <SubPageNavigationTabs
              tabs={[
                {
                  label: formatMessage({
                    description:
                      "Redemption page > sub page tabs > redeem tab label",
                    defaultMessage: "Redeem",
                  }),
                  path: "/my-rewards/redeem",
                },
                {
                  label: formatMessage({
                    description: "Redemption page > past redemptions tab label",
                    defaultMessage: "Past redemptions",
                  }),
                  path: "/my-rewards/past-redemptions",
                },
              ]}
              hostPageName="Redemption"
            />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}
