import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RewardsAppAuthenticatedInitializationDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RewardsAppAuthenticatedInitializationDataQuery = (
  { __typename?: 'Query' }
  & { getMyRewardsUser: (
    { __typename?: 'RewardsUser' }
    & Pick<Types.RewardsUser, 'id' | 'locale' | 'referralsEnabled'>
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'leaderboardEnabled' | 'drawsEnabled' | 'hasAccessToCaribouSponsoredDraws' | 'allowedRedemptionMethods' | 'socialFeedEnabled'>
    & { whiteLabelConfig?: Types.Maybe<(
      { __typename?: 'RewardsOrganizationWhiteLabelConfig' }
      & Pick<Types.RewardsOrganizationWhiteLabelConfig, 'id' | 'rewardsProgramShortName' | 'faviconImageUrl' | 'primaryColor' | 'secondaryColor' | 'rewardsPageTabIconUrl'>
    )> }
  ) }
);


export const RewardsAppAuthenticatedInitializationDataDocument = gql`
    query RewardsAppAuthenticatedInitializationData {
  getMyRewardsUser {
    id
    locale
    referralsEnabled
  }
  getMyRewardsOrganization {
    id
    leaderboardEnabled
    drawsEnabled
    hasAccessToCaribouSponsoredDraws
    allowedRedemptionMethods
    socialFeedEnabled
    whiteLabelConfig {
      id
      rewardsProgramShortName
      faviconImageUrl
      primaryColor
      secondaryColor
      rewardsPageTabIconUrl
    }
  }
}
    `;

/**
 * __useRewardsAppAuthenticatedInitializationDataQuery__
 *
 * To run a query within a React component, call `useRewardsAppAuthenticatedInitializationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsAppAuthenticatedInitializationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsAppAuthenticatedInitializationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsAppAuthenticatedInitializationDataQuery(baseOptions?: Apollo.QueryHookOptions<RewardsAppAuthenticatedInitializationDataQuery, RewardsAppAuthenticatedInitializationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsAppAuthenticatedInitializationDataQuery, RewardsAppAuthenticatedInitializationDataQueryVariables>(RewardsAppAuthenticatedInitializationDataDocument, options);
      }
export function useRewardsAppAuthenticatedInitializationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsAppAuthenticatedInitializationDataQuery, RewardsAppAuthenticatedInitializationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsAppAuthenticatedInitializationDataQuery, RewardsAppAuthenticatedInitializationDataQueryVariables>(RewardsAppAuthenticatedInitializationDataDocument, options);
        }
export type RewardsAppAuthenticatedInitializationDataQueryHookResult = ReturnType<typeof useRewardsAppAuthenticatedInitializationDataQuery>;
export type RewardsAppAuthenticatedInitializationDataLazyQueryHookResult = ReturnType<typeof useRewardsAppAuthenticatedInitializationDataLazyQuery>;
export type RewardsAppAuthenticatedInitializationDataQueryResult = Apollo.QueryResult<RewardsAppAuthenticatedInitializationDataQuery, RewardsAppAuthenticatedInitializationDataQueryVariables>;