import { reportError } from "@rewards-web/shared/modules/error";

import { usePointBalance } from "../../../shared/modules/point-balance";
import { useRewardsDataQuery } from "./query.generated";

/**
 * Convenience hook to get the data for the redemption page,
 * since we need this on multiple pages.
 */
export function useRedemptionPageData() {
  const query = useRewardsDataQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });
  const pointBalance = usePointBalance({ refreshOnMount: true });

  const data = (() => {
    if (!query.data || typeof pointBalance.computedBalance === "undefined") {
      return null;
    }

    const orgData = query.data.getMyRewardsOrganization;
    const userData = query.data.getMyRewardsUser;

    const isLocked =
      pointBalance.computedBalance < orgData.minimumPointsNeededToRedeem;

    return {
      redemptionMethods: orgData.allowedRedemptionMethods,
      pointsPerDollar: orgData.pointsPerDollar,
      pointsAvailableToRedeem: pointBalance.computedBalance,
      isLocked,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      minimumPointsToRedeem: orgData.minimumPointsNeededToRedeem,
    };
  })();

  return {
    ...query,
    data,
  };
}
