/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { CatalogGiftcardSelectorItem } from "./catalog-giftcard-selector-item";
import giftBoxImageUrl from "./graphics/gift-box.png";
import giftCardImageUrl from "./graphics/gift-card.png";

interface CatalogGiftcardSelectorProps {
  methods: RedemptionMethod[];
}

export function CatalogGiftcardSelector({
  methods,
}: CatalogGiftcardSelectorProps) {
  const track = useTrack();
  const { formatMessage } = useFormatters();

  useEffect(() => {
    track("Viewed redemption selector", { methods });
  }, [track, methods]);

  return (
    <div
      css={(theme: AppTheme) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(2)};

        @media (min-width: 470px) {
          grid-template-columns: repeat(2, 1fr);
        }
      `}
    >
      {methods.includes(RedemptionMethod.Catalog) && (
        <CatalogGiftcardSelectorItem
          title={formatMessage({
            defaultMessage: "Gifts",
            description:
              "Points summary page > redeem > category selector > gifts title",
          })}
          description={formatMessage({
            defaultMessage: "Turn your points into meaningful gifts",
            description:
              "Points summary page > redeem > category selector > gifts description",
          })}
          iconSrc={giftBoxImageUrl}
          iconAlt="Gifts"
          linkTo="/redeem-gifts"
          trackingItemKey="gifts"
        />
      )}

      {methods.includes(RedemptionMethod.GenericGiftCardLink) && (
        <CatalogGiftcardSelectorItem
          title={formatMessage({
            defaultMessage: "E-Giftcards",
            description:
              "Points summary page > redeem > category selector > e-giftcards title",
          })}
          description={formatMessage({
            defaultMessage: "Choose giftcards from a variety of options",
            description:
              "Points summary page > redeem > category selector > e-giftcards description",
          })}
          iconSrc={giftCardImageUrl}
          iconAlt="E-Giftcards"
          linkTo="/redeem-gift-cards"
          trackingItemKey="gift_cards"
        />
      )}
    </div>
  );
}
