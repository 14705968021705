/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode, useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import achievedGoalCoinsFallingUrl from "./graphics/achieved-goal-coins-falling.jpeg";
import CoinPointsIconUrl from "./graphics/coin-points.png";
import DrawTicketIconUrl from "./graphics/ticket-icon.png";

export interface BaseGoalAchievedModalProps {
  open: boolean;
  id: string;
  pointsEarned: number;
  caribouSponsoredDrawTickets: number;
  icon: JSX.Element;
  goalVisitStartDate: Date | null;
  goalDescription: string | null;
  analyticsData: { [key: string]: any };
  onClose(): void;
  onOpened(): void;
}

export function BaseGoalAchievedModal({
  open,
  id,
  pointsEarned,
  caribouSponsoredDrawTickets,
  icon,
  goalVisitStartDate,
  goalDescription,
  analyticsData,
  onClose,
  onOpened,
}: BaseGoalAchievedModalProps) {
  const { formatMessage, formatDate } = useFormatters();
  const track = useTrack();

  const { ref: buttonRef, height: buttonHeight } = useRefHeight({});

  useEffect(() => {
    if (open) {
      track("Viewed goal achieved modal", {
        pointsEarned,
        caribouSponsoredDrawTickets,
        ...analyticsData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDismiss = async () => {
    track("Dismissed goal achieved modal", analyticsData);
    onClose();
  };
  const tickets_text = (chunks: ReactNode[]) => (
    <Typography
      variant="h4"
      align="center"
      color="text.primary"
      display="block"
    >
      <img
        src={DrawTicketIconUrl}
        alt="Draw ticket"
        css={(theme: AppTheme) => css`
          width: 28px;
          margin-right: ${theme.spacing(0.7)};
        `}
      />
      {chunks}
    </Typography>
  );
  const points_text = (chunks: ReactNode[]) => (
    <Typography
      variant="h4"
      align="center"
      color="text.primary"
      display="block"
    >
      <img
        src={CoinPointsIconUrl}
        alt="Rewards point"
        css={(theme: AppTheme) => css`
          width: 24px;
          margin-right: ${theme.spacing(0.7)};
        `}
      />
      {chunks}
    </Typography>
  );

  return (
    <ResponsiveDialog
      backgroundColor="white"
      mobileType="dialog"
      paddingBottom={buttonHeight}
      open={open}
      onOpened={onOpened}
    >
      <div
        css={(theme: AppTheme) => css`
          padding: 0 ${theme.spacing(2)} 0 ${theme.spacing(2)};
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
        `}
      >
        <div>
          <img
            src={achievedGoalCoinsFallingUrl}
            alt="coins falling"
            css={(theme: AppTheme) =>
              css`
                height: 230px;
                margin-top: ${theme.spacing(-1.5)};
              `
            }
          />
        </div>
        <Typography
          variant="h2"
          align="center"
          color="text.primary"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(2)};
            line-height: 40px;
            font-size: 30px;
          `}
        >
          <FormattedMessage
            defaultMessage="Great job!"
            description="Goal achieved modal > title"
          />
        </Typography>
        <div
          css={css`
            width: 250px;
          `}
        >
          <Typography variant="footnote" align="center" color="#636666">
            {(() => {
              if (pointsEarned && caribouSponsoredDrawTickets) {
                return formatMessage(
                  {
                    defaultMessage:
                      "You earned <points_text>{points_earned, number} Points &</points_text> <tickets_text>{draw_tickets, number} Draw {draw_tickets, plural, one {Ticket} other {Tickets}}</tickets_text> for accomplishing the following goal",
                    description:
                      "Goal achieved modal > content > points and draw tickets",
                  },
                  {
                    points_text,
                    tickets_text,
                    points_earned: pointsEarned,
                    draw_tickets: caribouSponsoredDrawTickets,
                  }
                );
              }
              if (pointsEarned) {
                return formatMessage(
                  {
                    defaultMessage:
                      "You earned <points_text>{points_earned, number} Points</points_text> for accomplishing the following goal",
                    description: "Goal achieved modal > content > points",
                  },
                  {
                    points_text,
                    tickets_text,
                    points_earned: pointsEarned,
                    draw_tickets: caribouSponsoredDrawTickets,
                  }
                );
              }
              return "";
            })()}
          </Typography>

          <div
            css={(theme: AppTheme) => css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              max-width: 270px;
              gap: ${theme.spacing(2)};
              margin: ${theme.spacing(3)} auto;
              @media (min-height: 725px) {
                margin: ${theme.spacing(5)} auto;
              }
            `}
          >
            <div
              css={(theme: AppTheme) => css`
                > * {
                  width: ${theme.spacing(6)};
                  height: ${theme.spacing(6)};
                }
              `}
            >
              {icon}
            </div>
            <div
              css={(theme: AppTheme) => css`
                display: flex;
                flex-direction: column;
              `}
            >
              <FormattedMessage
                defaultMessage="<goal_description_heading>{goal_description_text}</goal_description_heading><goal_achieved_on>on {visit_start_date}</goal_achieved_on>"
                description="Goal achieved modal > content > goal description"
                values={{
                  goal_description_text: goalDescription,
                  goal_description_heading: (nodes) => (
                    <Typography
                      variant="h6"
                      align="center"
                      css={css`
                        font-weight: 600 !important;
                      `}
                    >
                      {nodes}
                    </Typography>
                  ),
                  visit_start_date: goalVisitStartDate
                    ? formatDate(new Date(goalVisitStartDate), {
                        weekday: "long",
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })
                    : "",
                  goal_achieved_on: (nodes) =>
                    goalVisitStartDate ? (
                      <Typography
                        variant="footnote"
                        align="center"
                        color="#636666"
                      >
                        {nodes}
                      </Typography>
                    ) : (
                      <></>
                    ),
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={buttonRef}
          css={(theme: AppTheme) => css`
            position: sticky;
            width: 100%;
            bottom: 0;
            padding-top: ${theme.spacing(2)};
            padding-bottom: ${theme.spacing(2)};
            background-color: white;
          `}
        >
          <div css={(theme: AppTheme) => css``}>
            <Button
              color="secondary"
              label={formatMessage({
                defaultMessage: "Got it!",
                description: "Goal achieved modal > button",
              })}
              onClick={handleDismiss}
            />
          </div>
        </div>
      </div>
    </ResponsiveDialog>
  );
}
