/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useRedemptionPageData } from "../../hooks";
import { CatalogGiftcardSelector } from "./catalog-giftcard-selector";
import { CatalogItemList } from "./catalog-item-list";
import { GiftCardList } from "./gift-card-list";

interface CatalogGiftcardRedemptionInfoProps {
  redemptionMethods: RedemptionMethod[];
  minimumPointsToRedeem: number;
  pointsPerDollar: number;
  email: string | undefined | null;
  phoneNumber: string | undefined | null;
}

export function CatalogGiftcardRedemptionInfo({
  redemptionMethods,
  minimumPointsToRedeem,
  pointsPerDollar,
  email,
  phoneNumber,
}: CatalogGiftcardRedemptionInfoProps) {
  if (
    !redemptionMethods.some((method) =>
      [RedemptionMethod.Catalog, RedemptionMethod.GenericGiftCardLink].includes(
        method
      )
    )
  ) {
    throw new Error(
      "Cannot render CatalogGiftcardRedemptionInfo without Catalog or GenericGiftCardLink redemption methods"
    );
  }

  const { formatMessage } = useFormatters();

  const redemptionPageData = useRedemptionPageData();

  if (redemptionPageData.error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description:
            "Points summary page > redeem page > points balance load error",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!redemptionPageData.data) {
    return <PageLoadingState />;
  }

  const { pointsAvailableToRedeem, isLocked } = redemptionPageData.data;

  if (typeof pointsAvailableToRedeem === "undefined") {
    return <PageLoadingState />;
  }

  const showSelector = redemptionMethods.length >= 2;

  const title = (() => {
    if (isLocked) {
      return formatMessage({
        defaultMessage: "🔒 Discover rewards",
        description: "Points summary page > redeem > locked header",
      });
    }

    return formatMessage({
      defaultMessage: "🤩 Redeem rewards now!",
      description: "Points summary page > redeem > unlocked header",
    });
  })();

  const subtitle = (() => {
    if (isLocked) {
      return formatMessage(
        {
          defaultMessage:
            "Earn {required_points, number} more points to unlock rewards from your favourite stores!",
          description: "Points summary page > redeem > locked subtitle",
        },
        {
          required_points: minimumPointsToRedeem - pointsAvailableToRedeem,
        }
      );
    }

    if (
      [
        RedemptionMethod.Catalog,
        RedemptionMethod.GenericGiftCardLink,
      ].every((method) => redemptionMethods.includes(method))
    ) {
      return formatMessage({
        defaultMessage:
          "Redeem your gifts and e-giftcards easily from a wide selection. Start exploring and treat yourself!",
        description:
          "Points summary page > redeem > gifts and e-giftcards subtitle",
      });
    }

    if (redemptionMethods.includes(RedemptionMethod.Catalog)) {
      return formatMessage({
        defaultMessage:
          "Redeem your gifts easily from a wide selection. Start exploring and treat yourself!",
        description: "Points summary page > redeem > gifts subtitle",
      });
    }

    // gift cards:
    return formatMessage({
      defaultMessage:
        "Select a card below to change your points into cash within a few clicks. You will need an email address.",
      description: "Points summary page > redeem > unlocked subtitle",
    });
  })();

  return (
    <div>
      <Typography variant="h3">{title}</Typography>
      <Typography
        variant="body"
        css={(appTheme: AppTheme) => css`
          margin-bottom: ${appTheme.spacing(2)};
          color: ${appTheme.palette.grey[800]};
        `}
      >
        {subtitle}
      </Typography>

      {isLocked && (
        <Button
          color="secondary"
          label={formatMessage({
            defaultMessage: "Earn more points",
            description:
              "Points summary page > redeem > earn more points button label",
          })}
          width="auto"
          linkTo={"/home"}
          css={(appTheme: AppTheme) => css`
            display: flex;
            padding: ${appTheme.spacing(1)} 0;
            margin-bottom: ${appTheme.spacing(3)};
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
          `}
        />
      )}

      {/*
        * Show the selector if the org has both gifts and e-gift cards.
        Otherwise, show just the list of gift cards or catalog items
      */}
      {showSelector ? (
        <CatalogGiftcardSelector methods={redemptionMethods} />
      ) : (
        <>
          {redemptionMethods[0] === RedemptionMethod.Catalog && (
            <CatalogItemList />
          )}
          {redemptionMethods[0] === RedemptionMethod.GenericGiftCardLink && (
            <GiftCardList
              isLocked={isLocked}
              pointsAvailableToRedeem={pointsAvailableToRedeem}
              pointsPerDollar={pointsPerDollar}
              minimumPointsToRedeem={minimumPointsToRedeem}
              email={email}
              phoneNumber={phoneNumber}
            />
          )}
        </>
      )}
    </div>
  );
}
