import { forwardRef, ReactNode } from "react";

import { BaseSocialPostCard } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import {
  BirthdaySocialPostCopyVariant,
  BirthdaySocialPostDetails,
  BirthdaySocialPostImageVariant,
  ReactionsSummary,
  ReactionType,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { SocialPostCardFragmentFragment } from "../../social-post-card-fragment.generated";
import { noTaggedUsersToDisplay } from "../common/lib";
import { SocialPostCardUserNameList } from "../common/social-post-card-user-name-list";
import birthdayBalloonsImageUrl from "./images/balloons.png";
import birthdayCakeLargeImageUrl from "./images/cake-large.png";
import birthdayCakeSmallImageUrl from "./images/cake-small.png";
import birthdayGiftsImageUrl from "./images/gifts.png";

export interface BirthdaySocialPostCardProps {
  commonData: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    showLessText: string;
  };
  details: {
    __typename?: "BirthdaySocialPostDetails";
  } & Pick<BirthdaySocialPostDetails, "id" | "imageVariant"> & {
      birthdayCopyVariant: BirthdaySocialPostDetails["copyVariant"];
    };
  taggedUsers: SocialPostCardFragmentFragment["taggedUsers"];
}

export const BirthdaySocialPostCard = forwardRef(
  ({ commonData, details, taggedUsers }: BirthdaySocialPostCardProps, ref) => {
    const { formatMessage } = useFormatters();
    const overlayText = formatMessage({
      description: "Social post card > birthday post > image overlay text",
      defaultMessage: "Happy birthday!",
    });

    if (noTaggedUsersToDisplay(taggedUsers)) {
      // filter out users who have opted out of appearing in the social feed
      // shouldn't happen, but we shouldnt' show the card if it somehow does happen
      return null;
    }

    return (
      <BaseSocialPostCard
        ref={ref}
        data={{
          ...commonData,
          tagText: formatMessage({
            description: "Social post card > birthday post > tag",
            defaultMessage: "Birthday",
          }),
          tagColor: "gold",
          messageText: (() => {
            const userNamesList = (
              <SocialPostCardUserNameList taggedUsers={taggedUsers} />
            );

            const bold = (nodes: ReactNode[]) => <strong>{nodes}</strong>;
            const br = () => <br />;

            switch (details.birthdayCopyVariant) {
              case BirthdaySocialPostCopyVariant.OneBirthday:
                return formatMessage(
                  {
                    description:
                      "Social post card > birthday post > message > one birthday",
                    defaultMessage:
                      "<bold>Happy Birthday, <bold>{name}</bold> 🎉</bold><br></br>Wishing you a fantastic year ahead filled with joy, success, and all the good things life has to offer. Enjoy your special day! 🎂🎈",
                  },
                  {
                    name: taggedUsers[0]!.firstNameLastInitial,
                    bold,
                    br,
                  }
                );
              case BirthdaySocialPostCopyVariant.OneBirthdayCheers:
                return formatMessage(
                  {
                    description:
                      "Social post card > birthday post > message > one birthday cheers variant",
                    defaultMessage:
                      "<bold>Cheers to You on Your Birthday, <bold>{name}</bold>! 🎈</bold><br></br>Sending our warmest birthday wishes to an incredible team member! Thank you for all that you do. May this year bring you all the happiness you deserve!",
                  },
                  {
                    name: taggedUsers[0]!.firstNameLastInitial,
                    bold,
                    br,
                  }
                );
              case BirthdaySocialPostCopyVariant.MultipleBirthdaysCelebrating:
                return (
                  <>
                    {formatMessage(
                      {
                        description:
                          "Social post card > birthday post > message > multiple birthdays celebrating variant",
                        defaultMessage:
                          "<bold>Celebrating {num_birthdays, number} Birthdays Today! 🎂</bold>✨<br></br>It's a big day! Join us in wishing a very happy birthday to {num_birthdays, number} of our dedicated team members. Your hard work and compassion make all the difference—may this year be your best one yet!",
                      },
                      { num_birthdays: taggedUsers.length, bold, br }
                    )}
                    {userNamesList}
                  </>
                );
              case BirthdaySocialPostCopyVariant.MultipleBirthdaysAmazing:
                return (
                  <>
                    {formatMessage(
                      {
                        description:
                          "Social post card > birthday post > message > multiple birthdays celebrating variant",
                        defaultMessage:
                          "<bold>Happy Birthday to Our Amazing Caregivers! 🎉</bold><br></br>Today, we're celebrating {num_birthdays, number} incredible team members on their special day! Wishing each of you a wonderful birthday filled with joy and appreciation.",
                      },
                      { num_birthdays: taggedUsers.length, bold, br }
                    )}
                    {userNamesList}
                  </>
                );
              case BirthdaySocialPostCopyVariant.MultipleBirthdaysShoutout:
              default:
                // fall back to this one if we can't recognize it
                return (
                  <>
                    {formatMessage(
                      {
                        description:
                          "Social post card > birthday post > message > multiple birthdays shoutout variant",
                        defaultMessage:
                          "<bold>A Big Birthday Shoutout! 🎈</bold><br></br>Today, we're thrilled to celebrate {num_birthdays, number} {num_birthdays, plural, one {team member} other {team members}} on their birthdays! Thank you for the amazing care you bring every day. Wishing each of you a fantastic year ahead filled with happiness and success!",
                      },
                      { num_birthdays: taggedUsers.length, bold, br }
                    )}
                    {userNamesList}
                  </>
                );
            }
          })(),
          image: (() => {
            switch (
              (details as Pick<
                BirthdaySocialPostDetails,
                "id" | "imageVariant"
              >).imageVariant
            ) {
              case BirthdaySocialPostImageVariant.Balloons:
                return {
                  url: birthdayBalloonsImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "center",
                    imageTintColor: "#316D9E",
                  },
                };
              case BirthdaySocialPostImageVariant.CakeLarge:
                return {
                  url: birthdayCakeLargeImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "top",
                    imageTintColor: "#20918A",
                  },
                };
              case BirthdaySocialPostImageVariant.CakeSmall:
                return {
                  url: birthdayCakeSmallImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "center",
                    imageTintColor: "#316D9E",
                  },
                };
              case BirthdaySocialPostImageVariant.Gifts:
                return {
                  url: birthdayGiftsImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "center",
                    imageTintColor: "#316D9E",
                  },
                };
              default:
                // fall back to no image if we don't recognize it in the frontend
                return null;
            }
          })(),
        }}
      />
    );
  }
);
