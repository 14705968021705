import { TypePolicies } from "@apollo/client";

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      listShareableJobPostings: {
        keyArgs: ["searchQuery", "filters"],
        merge(
          existing: undefined | { total: number; items: any[] },
          incoming: { total: number; items: any[] },
          { args }
        ) {
          if (!existing) {
            return incoming;
          }

          const mergedItems = existing.items.slice();

          const start = args!.offset;
          for (let i = 0; i < incoming.items.length; i++) {
            mergedItems[start + i] = incoming.items[i];
          }

          return {
            total: incoming.total,
            items: mergedItems,
          };
        },
      },
      listMySocialFeedPostsByCursor: {
        // this must be updated if we can pass a filter when querying the social feed:
        keyArgs: false,

        // merge results by concatenating previous with next results
        merge(
          existing:
            | undefined
            | { nextCursor: string | null | undefined; items: any[] },
          incoming: { nextCursor: string | null | undefined; items: any[] },
          options
        ) {
          if (!existing) {
            // this is the first page
            return incoming;
          }

          if (!options.args?.cursor) {
            // this is the first page, so we can just return the incoming.
            // this may happen if the user navigates to the social feed,
            // navigates to a different page, and then returns to the social feed.
            // this may cause more than `limit` items to be returned,
            // which may cause items to be dropped, so we should just return
            // the incoming items as is (the first page).
            // the user can paginate through the rest of the items again
            return incoming;
          }

          // the new page may be either:
          // - a page we already have, or
          // - a new page that we don't have, but it may contain some of the same items
          //   as the previous page

          // so we need to return a new set of items that
          // appends only the new items from the new page
          // (assuming no newer pages are fetched in the meantime,
          // which are handled by the args.cursor check above)

          const existingIds = new Set(existing.items.map((item) => item.__ref));

          const newItems = incoming.items.filter(
            (item) => !existingIds.has(item.__ref)
          );

          // Combine existing items with only the new unique items
          const mergedItems = [...existing.items, ...newItems];

          return {
            nextCursor: incoming.nextCursor,
            items: mergedItems,
          };
        },
      },
    },
  },
};
