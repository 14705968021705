import { forwardRef, ReactNode, useEffect } from "react";

import {
  BaseSocialPostCard,
  BaseSocialPostCardImage,
} from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import {
  ReactionsSummary,
  ReactionType,
  WorkMilestoneSocialPostCopyVariant,
  WorkMilestoneSocialPostDetails,
  WorkMilestoneSocialPostMilestoneHours,
  WorkMilestoneSocialPostMilestoneVisit,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import { SocialPostCardFragmentFragment } from "../../social-post-card-fragment.generated";
import { noTaggedUsersToDisplay } from "../common/lib";
import { SocialPostCardUserNameList } from "../common/social-post-card-user-name-list";
import barChartImageUrl from "./images/bar-chart.png";
import flagOnMountainImageUrl from "./images/flag-on-mountain.png";
import starMedalImageUrl from "./images/star-medal.png";
import trophiesImageUrl from "./images/trophies.png";

export interface WorkMilestoneSocialPostCardProps {
  commonData: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    showLessText: string;
  };
  postId: string;
  details: { __typename?: "WorkMilestoneSocialPostDetails" } & Pick<
    WorkMilestoneSocialPostDetails,
    "id"
  > & {
      milestoneCopyVariant: WorkMilestoneSocialPostDetails["copyVariant"];
    } & {
      milestone:
        | ({ __typename: "WorkMilestoneSocialPostMilestoneVisit" } & Pick<
            WorkMilestoneSocialPostMilestoneVisit,
            "id" | "visitNumber"
          >)
        | ({ __typename: "WorkMilestoneSocialPostMilestoneHours" } & Pick<
            WorkMilestoneSocialPostMilestoneHours,
            "id" | "numHours"
          >);
    };
  taggedUsers: SocialPostCardFragmentFragment["taggedUsers"];
}

export const WorkMilestoneSocialPostCard = forwardRef(
  (
    {
      postId,
      commonData,
      details,
      taggedUsers,
    }: WorkMilestoneSocialPostCardProps,
    ref
  ) => {
    const { formatMessage } = useFormatters();

    const overlayText = (() => {
      switch (details.milestone.__typename) {
        case "WorkMilestoneSocialPostMilestoneVisit":
          switch (details.milestone.visitNumber) {
            case 1:
              return formatMessage({
                description:
                  "Social post card > work milestones post > image overlay text > first shift",
                defaultMessage: "First shift",
              });
            case 2:
              return formatMessage({
                description:
                  "Social post card > work milestones post > image overlay text > second shift",
                defaultMessage: "Second shift",
              });
            default:
              return formatMessage(
                {
                  description:
                    "Social post card > work milestones post > image overlay text > shift fallback",
                  defaultMessage:
                    "{shift_number, selectordinal, one {1st} two {2nd} few {3rd} other {#th}} shift",
                },
                { shift_number: details.milestone.visitNumber }
              );
          }
        case "WorkMilestoneSocialPostMilestoneHours":
          return formatMessage(
            {
              description:
                "Social post card > work milestones post > image overlay text > hours",
              defaultMessage:
                "{num_hours, number} {num_hours, plural, one {hour} other {hours}}",
            },
            {
              num_hours: details.milestone.numHours,
            }
          );
        default:
          // unrecognized milestone type - don't show overlay
          // (probably won't happen)
          return null;
      }
    })();

    const image = ((): BaseSocialPostCardImage => {
      switch (details.milestone.__typename) {
        case "WorkMilestoneSocialPostMilestoneVisit":
          return {
            url: starMedalImageUrl,
            overlayText: overlayText
              ? {
                  text: overlayText,
                  textColor: "#EA2276",
                  imageTintColor: "transparent",
                  position: "right",
                }
              : undefined,
          };
        case "WorkMilestoneSocialPostMilestoneHours":
          switch (details.milestone.numHours) {
            case 100:
            case 200:
              return {
                url: barChartImageUrl,
                overlayText: overlayText
                  ? {
                      text: overlayText,
                      textColor: "#EA2276",
                      imageTintColor: "transparent",
                      position: "left",
                    }
                  : undefined,
              };
            case 1000:
              return {
                url: flagOnMountainImageUrl,
                overlayText: overlayText
                  ? {
                      text: overlayText,
                      textColor: "white",
                      imageTintColor: "transparent",
                      position: "left",
                    }
                  : undefined,
              };
            case 50:
            case 500:
            default:
              // fall back to trophies if we don't recognize the number
              return {
                url: trophiesImageUrl,
                overlayText: overlayText
                  ? {
                      text: overlayText,
                      textColor: "#1D51D6",
                      imageTintColor: "transparent",
                      position: "right",
                    }
                  : undefined,
              };
          }
        default:
          // unrecognized milestone type - don't show image
          // (this shouldn't happen)
          return null;
      }
    })();

    const messageText = (() => {
      const userNamesList = (
        <SocialPostCardUserNameList taggedUsers={taggedUsers} />
      );

      const bold = (nodes: ReactNode[]) => <strong>{nodes}</strong>;

      switch (details.milestoneCopyVariant) {
        case WorkMilestoneSocialPostCopyVariant.VisitsFirstShiftMultipleCaregivers: {
          if (!isVisitMilestone(details.milestone)) {
            return null;
          }

          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > work milestones post > copy variant > visits first shift multiple caregivers",
                  defaultMessage:
                    "🌈 Cheers to {num_people, number} caregivers for finishing shift #1! Every step counts. Let's show some love and celebrate this great start!",
                },
                { num_people: taggedUsers.length }
              )}
              {userNamesList}
            </>
          );
        }
        case WorkMilestoneSocialPostCopyVariant.VisitsFirstShiftOneCaregiver: {
          if (!isVisitMilestone(details.milestone)) {
            return null;
          }

          return formatMessage(
            {
              description:
                "Social post card > work milestones post > copy variant > visits first shift one caregiver",
              defaultMessage:
                "🌈 Cheers to <bold>{name}</bold> for finishing their shift #1! Every step counts. Let's show some love and celebrate this great start!",
            },
            {
              bold,
              name: taggedUsers[0]!.firstNameLastInitial,
            }
          );
        }
        case WorkMilestoneSocialPostCopyVariant.VisitsFirstShiftOneCaregiverUnlocked: {
          if (!isVisitMilestone(details.milestone)) {
            return null;
          }

          return formatMessage(
            {
              description:
                "Social post card > work milestones post > copy variant > visits first shift one caregiver unlocked",
              defaultMessage:
                "🏆 First shift milestone unlocked! <bold>{name}</bold>, you're off to an amazing start—here's to many more impactful moments ahead.",
            },
            {
              bold,
              name: taggedUsers[0]!.firstNameLastInitial,
            }
          );
        }
        case WorkMilestoneSocialPostCopyVariant.VisitsSecondShiftMultipleCaregivers: {
          if (!isVisitMilestone(details.milestone)) {
            return null;
          }

          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > work milestones post > copy variant > visits second shift multiple caregivers",
                  defaultMessage:
                    "🎊 Way to go! {num_people, number} caregivers completed their second shift! Let's keep the momentum going—drop a like to cheer them on!",
                },
                { num_people: taggedUsers.length }
              )}
              {userNamesList}
            </>
          );
        }

        case WorkMilestoneSocialPostCopyVariant.VisitsSecondShiftOneCaregiver: {
          if (!isVisitMilestone(details.milestone)) {
            return null;
          }

          return formatMessage(
            {
              description:
                "Social post card > work milestones post > copy variant > visits second shift one caregiver",
              defaultMessage:
                "🎊 Way to go! <bold>{name}</bold> completed their second shift! Let's keep the momentum going—drop a like to cheer them on!",
            },
            {
              bold,
              name: taggedUsers[0]!.firstNameLastInitial,
            }
          );
        }
        case WorkMilestoneSocialPostCopyVariant.HoursMultipleCaregiversAmazing:
          if (!isHoursMilestone(details.milestone)) {
            return null;
          }

          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > work milestones post > copy variant > hours multiple caregivers amazing",
                  defaultMessage:
                    "💪 Amazing! {num_people, number} caregivers just reached {num_hours, number} hours of care! Let's give a big round of applause and celebrate!",
                },
                {
                  num_people: taggedUsers.length,
                  num_hours: details.milestone.numHours,
                }
              )}
              {userNamesList}
            </>
          );
        case WorkMilestoneSocialPostCopyVariant.HoursMultipleCaregiversWayToGo:
          if (!isHoursMilestone(details.milestone)) {
            return null;
          }

          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > work milestones post > copy variant > hours multiple caregivers way to go",
                  defaultMessage:
                    "🏆 Way to go, {num_people, number} caregivers just reached {num_hours, number} hours of care! Let's show some appreciation for this amazing milestone!",
                },
                {
                  num_people: taggedUsers.length,
                  num_hours: details.milestone.numHours,
                }
              )}
              {userNamesList}
            </>
          );
        case WorkMilestoneSocialPostCopyVariant.HoursMultipleCaregiversHatsOff:
          if (!isHoursMilestone(details.milestone)) {
            return null;
          }

          return (
            <>
              {formatMessage(
                {
                  description:
                    "Social post card > work milestones post > copy variant > hours multiple caregivers hats off",
                  defaultMessage:
                    "🙌 Hats off to {num_people, number} caregivers for crossing {num_hours, number} hours of care! Let's make this milestone memorable—drop a like to show your support!",
                },
                {
                  num_people: taggedUsers.length,
                  num_hours: details.milestone.numHours,
                }
              )}
              {userNamesList}
            </>
          );
        case WorkMilestoneSocialPostCopyVariant.HoursOneCaregiverWow: {
          if (!isHoursMilestone(details.milestone)) {
            return null;
          }

          return formatMessage(
            {
              description:
                "Social post card > work milestones post > copy variant > hours one caregiver wow",
              defaultMessage:
                "🎉 Wow! <bold>{name}</bold> just reached an incredible {num_hours, number} hours of care! Your dedication inspires us all — let's celebrate this amazing achievement!",
            },
            {
              bold,
              name: taggedUsers[0]!.firstNameLastInitial,
              num_hours: details.milestone.numHours,
            }
          );
        }
        case WorkMilestoneSocialPostCopyVariant.HoursOneCaregiverShout: {
          if (!isHoursMilestone(details.milestone)) {
            return null;
          }

          return formatMessage(
            {
              description:
                "Social post card > work milestones post > copy variant > hours one caregiver shout",
              defaultMessage:
                "💡 Shout to <bold>{name}</bold> for hitting {num_hours, number} hours of care! Thank you for your commitment—keep up the amazing work!",
            },
            {
              bold,
              name: taggedUsers[0]!.firstNameLastInitial,
              num_hours: details.milestone.numHours,
            }
          );
        }
        case WorkMilestoneSocialPostCopyVariant.HoursOneCaregiverIncredible: {
          if (!isHoursMilestone(details.milestone)) {
            return null;
          }

          return formatMessage(
            {
              description:
                "Social post card > work milestones post > copy variant > hours one caregiver incredible",
              defaultMessage:
                "🌟 Incredible milestone alert! <bold>{name}</bold> has achieved {num_hours, number} hours of care! We couldn't be prouder—keep up the amazing work!",
            },
            {
              bold,
              name: taggedUsers[0]!.firstNameLastInitial,
              num_hours: details.milestone.numHours,
            }
          );
        }
        default:
          // unrecognized milestone copy variant - don't show message
          return null;
      }
    })();

    useEffect(() => {
      if (!messageText) {
        reportError(
          new Error(
            `No message text for work milestones social post ${postId}; will not display card`
          )
        );
      }
    }, [messageText, postId]);

    if (!messageText) {
      // unrecognized milestone copy variant - don't show message
      return null;
    }

    if (noTaggedUsersToDisplay(taggedUsers)) {
      // filter out users who have opted out of appearing in the social feed
      // shouldn't happen, but we shouldn't show the card if it somehow does happen
      return null;
    }

    return (
      <BaseSocialPostCard
        ref={ref}
        data={{
          ...commonData,
          tagText: formatMessage({
            description: "Social post card > work milestones post > tag text",
            defaultMessage: "Milestone",
          }),
          tagColor: "gold",
          messageText,
          image,
        }}
      />
    );
  }
);

function isVisitMilestone(
  milestone: WorkMilestoneSocialPostCardProps["details"]["milestone"]
): milestone is { __typename: "WorkMilestoneSocialPostMilestoneVisit" } & Pick<
  WorkMilestoneSocialPostMilestoneVisit,
  "id" | "visitNumber"
> {
  return milestone.__typename === "WorkMilestoneSocialPostMilestoneVisit";
}

function isHoursMilestone(
  milestone: WorkMilestoneSocialPostCardProps["details"]["milestone"]
): milestone is { __typename: "WorkMilestoneSocialPostMilestoneHours" } & Pick<
  WorkMilestoneSocialPostMilestoneHours,
  "id" | "numHours"
> {
  return milestone.__typename === "WorkMilestoneSocialPostMilestoneHours";
}
