/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { ErrorPage } from "@rewards-web/shared/pages/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../shared/page-content-container";
import { useRedemptionPageData } from "../hooks";
import { PaycheckRedemptionInfo } from "../paycheck-redemption-info";
import { CatalogGiftcardRedemptionInfo } from "./catalog-giftcard-redemption-info";

export function RedeemPage() {
  const pageDataQuery = useRedemptionPageData();

  if (pageDataQuery.error) {
    return <ErrorPage />;
  }

  if (!pageDataQuery.data) {
    return <PageLoadingState />;
  }
  const {
    redemptionMethods,
    pointsPerDollar,
    minimumPointsToRedeem,
    email,
    phoneNumber,
  } = pageDataQuery.data;

  return (
    <div
      css={(appTheme: AppTheme) => css`
        background-color: ${appTheme.palette.background.paper};
        height: 100%;
      `}
    >
      <PageContentContainer
        css={(appTheme: AppTheme) =>
          css`
            padding-top: ${appTheme.spacing(3.75)};
          `
        }
      >
        {redemptionMethods.some((method) =>
          [
            RedemptionMethod.GenericGiftCardLink,
            RedemptionMethod.Catalog,
          ].includes(method)
        ) && (
          <CatalogGiftcardRedemptionInfo
            redemptionMethods={redemptionMethods}
            minimumPointsToRedeem={minimumPointsToRedeem}
            pointsPerDollar={pointsPerDollar}
            email={email}
            phoneNumber={phoneNumber}
          />
        )}
        {redemptionMethods.includes(RedemptionMethod.Payroll) && (
          <PaycheckRedemptionInfo />
        )}
      </PageContentContainer>
    </div>
  );
}
