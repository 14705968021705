/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Divider } from "@rewards-web/shared/components/divider";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { InsufficientPointsDrawer } from "../../../points-progress/insufficient-points-drawer";
import { RedeemPointsDrawer } from "../../../points-progress/redeem-points-drawer";
import { DisclaimersFooter } from "./disclaimers-footer";
import { Giftcard } from "./gift-card";
import { useListGiftCardOptionsQuery } from "./list-gift-card-options.generated";
import SeeMoreCard from "./see-more-card.png";

interface GiftCardListProps {
  isLocked: boolean;
  pointsAvailableToRedeem: number;
  pointsPerDollar: number;
  minimumPointsToRedeem: number;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
}

export function GiftCardList({
  isLocked,
  phoneNumber,
  pointsAvailableToRedeem,
  pointsPerDollar,
  minimumPointsToRedeem,
  email,
}: GiftCardListProps) {
  const { formatMessage } = useFormatters();

  const [
    isInsufficientPointsDrawerOpen,
    setIsInsufficientPointsDrawerOpen,
  ] = useState(false);
  const [isRedeemDrawerOpen, setIsRedeemDrawerOpen] = useState(false);

  const { data, error } = useListGiftCardOptionsQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });

  if (error) {
    return (
      <Alert
        severity="error"
        message={formatMessage({
          description: "Points summary page > redeem page > list gift cards",
          defaultMessage: "Something went wrong. Please try again later.",
        })}
      />
    );
  }

  if (!data) {
    return <PageLoadingState />;
  }

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          @media (min-width: 320px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (min-width: 550px) {
            grid-template-columns: repeat(3, 1fr);
          }

          gap: ${theme.spacing(2)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        {data?.listGiftCardOptions?.items.map((reward, index) => (
          <Giftcard
            key={index}
            imageUrl={reward.imageUrl}
            brandName={reward.brandName}
            minPointsAllowed={reward.minPointsAllowed}
            maxPointsAllowed={reward.maxPointsAllowed}
            isLocked={isLocked}
            setIsInsufficientPointsDrawerOpen={
              setIsInsufficientPointsDrawerOpen
            }
            setIsRedeemDrawerOpen={setIsRedeemDrawerOpen}
          />
        ))}
        {/* see more card */}
        <Giftcard
          imageUrl={SeeMoreCard}
          brandName={formatMessage({
            defaultMessage: "More Gift Cards",
            description: "Points summary page > redeem > more gift Cards",
          })}
          minPointsAllowed={1000}
          maxPointsAllowed={20000}
          isLocked={isLocked}
          setIsInsufficientPointsDrawerOpen={setIsInsufficientPointsDrawerOpen}
          setIsRedeemDrawerOpen={setIsRedeemDrawerOpen}
        />
      </div>
      <RedeemPointsDrawer
        phoneNumber={phoneNumber}
        open={isRedeemDrawerOpen}
        onClose={() => setIsRedeemDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        pointsPerDollar={pointsPerDollar}
        redemptionMethods={[RedemptionMethod.GenericGiftCardLink]}
        email={email}
      />
      <InsufficientPointsDrawer
        open={isInsufficientPointsDrawerOpen}
        onClose={() => setIsInsufficientPointsDrawerOpen(false)}
        pointsAvailableToRedeem={pointsAvailableToRedeem}
        minimumPointsToRedeem={minimumPointsToRedeem}
      />

      <Divider
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <DisclaimersFooter />
    </>
  );
}
