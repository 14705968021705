/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PointsIcon } from "../../../../../../../shared/points-icon";

export interface RedeemDrawerRequireEmailDisclaimerStepProps {
  pointsAvailableToRedeem: number;
  onCancel: () => void;
  onEnterUpdateEmailFlow: () => void;
}

export function RedeemDrawerRequireEmailDisclaimerStep({
  pointsAvailableToRedeem,
  onCancel,
  onEnterUpdateEmailFlow,
}: RedeemDrawerRequireEmailDisclaimerStepProps) {
  const { formatMessage } = useFormatters();

  const track = useTrack();

  useEffect(() => {
    track("Viewed giftcard redemption flow - require email disclaimer step");
  }, [track]);

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}
      >
        <div
          css={(appTheme: AppTheme) => css`
            text-transform: uppercase;
            padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1.5)};
            border-radius: 500px;
            background-color: ${appTheme.palette.tertiary.main};
            display: flex;
            align-items: center;
            gap: ${appTheme.spacing(1.25)};
            margin-bottom: ${appTheme.spacing(2.5)};
          `}
        >
          <PointsIcon />
          <Typography variant="caption" color="white">
            {formatMessage(
              {
                defaultMessage: "{redeemable_points, number} points",
                description: "Redeem points drawer > number of points",
              },
              { redeemable_points: pointsAvailableToRedeem }
            )}
          </Typography>
        </div>
        <Typography variant="h5">
          <FormattedMessage
            description="Redeem points drawer > require email disclaimer > title"
            defaultMessage="We need your email"
          />
        </Typography>
        <Typography
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(2.5)};
            `
          }
        >
          {/* TODO(@thomasdashney) customize copy via prop */}
          <FormattedMessage
            description="Redeem points drawer > require email disclaimer > title"
            defaultMessage="We couldn't find your email address in our records. Please update your email to receive your gift card."
          />
        </Typography>
      </div>
      <div
        css={(appTheme: AppTheme) => css`
          display: flex;
          align-items: center;
          margin-top: ${appTheme.spacing(3.75)};
          gap: ${appTheme.spacing(1.5)};
        `}
      >
        <Button
          label={formatMessage({
            defaultMessage: "Cancel",
            description: "Redeem points drawer > cancel button",
          })}
          onClick={() => {
            track(
              "Cancel pressed in giftcard redemption flow - require email disclaimer step"
            );
            onCancel();
          }}
          variant="outlined"
        />
        <Button
          onClick={() => {
            track(
              "Add email pressed in giftcard redemption flow - require email disclaimer step"
            );
            onEnterUpdateEmailFlow();
          }}
          color="secondary"
          label={formatMessage({
            defaultMessage: "Add email",
            description:
              "Redeem points drawer > require email disclaimer > add email button label",
          })}
        />
      </div>
    </>
  );
}
