/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import assert from "assert";
import { useEffect, useRef, useState } from "react";

import { Typography } from "@rewards-web/shared/components/typography";
import { useRefHeight } from "@rewards-web/shared/hooks/use-ref-height";
import { serializePhoneNumber } from "@rewards-web/shared/lib/serialize-phone-number";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAuth } from "../../../../../../../shared/modules/auth";
import {
  RewardsAppAuthError,
  RewardsAppAuthErrorCode,
} from "../../../../../../../shared/modules/auth/errors";
import PaperPlaneUrl from "./graphics/paper-plane.png";
import { RedeemDrawerVerifySmsForm } from "./verify-sms-step-form";

export interface RedeemDrawerVerifySmsProps {
  phoneNumber: string | null | undefined;
  organizationId?: string;
  onCancel: (() => void) | null;
  onSmsVerified: () => void;
}

export function RedeemDrawerVerifySms({
  phoneNumber,
  organizationId,
  onCancel,
  onSmsVerified,
}: RedeemDrawerVerifySmsProps) {
  const { formatMessage } = useFormatters();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    signInWithPhoneVerification: sendPhoneVerificationCode,
    submitPhoneVerificationCode,
  } = useAuth();

  const snackbar = useSnackbar();
  const track = useTrack();
  const { ref: buttonsRef, height: buttonsHeight } = useRefHeight({
    isReady: true,
  });

  const isMobileScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("xs")
  );

  useEffect(() => {
    track("Viewed giftcard redemption flow - verify SMS step");
  }, [track]);

  const sendVerificationCode = async ({
    initial,
    organizationId,
  }: {
    initial: boolean;
    organizationId: string;
  }) => {
    try {
      assert(phoneNumber, "expect phone number when updating user email");
      await sendPhoneVerificationCode({
        phoneNumber: serializePhoneNumber(phoneNumber),
        organizationId,
      });
      if (!initial) {
        snackbar.show({
          severity: "success",
          message: formatMessage({
            description: "Login page > resend code success snackbar",
            defaultMessage: "A new code has been sent",
          }),
        });
      }
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(
        formatMessage({
          description: "Redeem points drawer > verify sms > send code error",
          defaultMessage:
            "An unexpected error occurred while trying to send your SMS code. Please try again.",
        })
      );
      reportError(error);
      track("Update email send SMS failed", { phoneNumber });
    }
  };

  const sentInitialCodeRef = useRef(false);
  // send initial verification code
  useEffect(() => {
    if (!sentInitialCodeRef.current && organizationId) {
      sendVerificationCode({ initial: true, organizationId });
      sentInitialCodeRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const smallScreen = useMediaQuery("(max-width: 400px)");

  const onSubmitVerificationCode = async (code: string) => {
    track("Verify code pressed in giftcard redemption flow - verify SMS step");
    try {
      await submitPhoneVerificationCode(code);
      setErrorMessage(null);
      track("Update email verify SMS succeeded");
      onSmsVerified();
    } catch (error) {
      if (
        error instanceof RewardsAppAuthError &&
        error.code === RewardsAppAuthErrorCode.INVALID_VERIFICATION_CODE
      ) {
        setErrorMessage(
          formatMessage({
            description:
              "Redeem points drawer > verify sms > invalid login code entered",
            defaultMessage: "Verification failed. Try again.",
          })
        );
      } else if (
        error instanceof RewardsAppAuthError &&
        error.code === RewardsAppAuthErrorCode.CHALLENGE_EXPIRED
      ) {
        setErrorMessage(
          formatMessage({
            description:
              "Redeem points drawer > verify sms > login code expired",
            defaultMessage:
              "The verification code has expired. Please try again.",
          })
        );
      } else if (
        error instanceof RewardsAppAuthError &&
        error.code === RewardsAppAuthErrorCode.EXCEEDED_MAX_ATTEMPTS
      ) {
        setErrorMessage(
          formatMessage({
            description:
              "Redeem points drawer > verify sms > exceeded max attempts",
            defaultMessage: "Exceeded max attempts. Please try again later.",
          })
        );
      } else {
        setErrorMessage(
          formatMessage({
            description:
              "Redeem points drawer > verify sms > unexpected error occurred",
            defaultMessage: "Unexpected error. Please try again.",
          })
        );
        reportError(error);
      }
      track("Update email send SMS failed", { phoneNumber });
    }
  };

  const description = formatMessage(
    {
      description: "Redeem points drawer > verify sms > description",
      defaultMessage: `Before proceeding with your request, for your security, we want to make sure it's really you.
        <paper_plane_icon></paper_plane_icon>
        A verification code was sent to you via SMS to {obfuscated_phone_number}.`,
    },
    {
      obfuscated_phone_number: `+1 (***) ***-${(phoneNumber ?? "").slice(-4)}`,
      paper_plane_icon: () => (
        <img
          src={PaperPlaneUrl}
          width={smallScreen ? "96px" : "135px"}
          alt={formatMessage({
            defaultMessage: "Paper plane icon",
            description:
              "Redeem points drawer > verify sms > paper plane icon alt",
          })}
          css={(theme: AppTheme) => css`
            display: block;
            margin: ${smallScreen
              ? theme.spacing(2, "auto")
              : theme.spacing(3.75, "auto", 3, "auto")};
          `}
        />
      ),
      br: () => <br />,
    }
  );

  return (
    <div
      css={(theme: AppTheme) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: ${theme.spacing(-2)};
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: ${isMobileScreen ? buttonsHeight + "px" : 0};
        position: relative;
      `}
    >
      <Typography
        variant="body"
        color="textPrimary"
        css={css`
          text-align: center;
          max-width: 330px;
        `}
      >
        {description}
      </Typography>

      <Typography
        css={(theme: AppTheme) => css`
          margin: ${theme.spacing(3)} 0;
          text-align: center;
        `}
        color={errorMessage ? "error" : undefined}
      >
        {errorMessage ??
          formatMessage({
            defaultMessage: "Please enter the code you received.",
            description:
              "Redeem points drawer > verify sms > error placeholder",
          })}
      </Typography>
      <RedeemDrawerVerifySmsForm
        onResend={
          organizationId
            ? () => {
                track(
                  "Resend code pressed in giftcard redemption flow - verify SMS step"
                );
                sendVerificationCode({ initial: false, organizationId });
              }
            : undefined
        }
        onSubmit={onSubmitVerificationCode}
        hasError={!!errorMessage}
        onClearErrorMessage={() => setErrorMessage(null)}
        onCancel={
          onCancel
            ? () => {
                track(
                  "Cancel pressed in giftcard redemption flow - verify SMS step"
                );
                onCancel();
              }
            : null
        }
        footerRef={buttonsRef}
      />
    </div>
  );
}
