import * as Types from '@rewards-web/shared/graphql-types';

import { SocialPostCardFragmentFragment } from './social-post-card-fragment.generated';
import { gql } from '@apollo/client';
import { SocialPostCardFragmentFragmentDoc } from './social-post-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RecordSocialPostViewsMutationVariables = Types.Exact<{
  postIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type RecordSocialPostViewsMutation = (
  { __typename?: 'Mutation' }
  & { recordViews: Array<(
    { __typename?: 'SocialPost' }
    & SocialPostCardFragmentFragment
  )> }
);


export const RecordSocialPostViewsDocument = gql`
    mutation RecordSocialPostViews($postIds: [ID!]!) {
  recordViews(postIds: $postIds) {
    ...SocialPostCardFragment
  }
}
    ${SocialPostCardFragmentFragmentDoc}`;
export type RecordSocialPostViewsMutationFn = Apollo.MutationFunction<RecordSocialPostViewsMutation, RecordSocialPostViewsMutationVariables>;

/**
 * __useRecordSocialPostViewsMutation__
 *
 * To run a mutation, you first call `useRecordSocialPostViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordSocialPostViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordSocialPostViewsMutation, { data, loading, error }] = useRecordSocialPostViewsMutation({
 *   variables: {
 *      postIds: // value for 'postIds'
 *   },
 * });
 */
export function useRecordSocialPostViewsMutation(baseOptions?: Apollo.MutationHookOptions<RecordSocialPostViewsMutation, RecordSocialPostViewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordSocialPostViewsMutation, RecordSocialPostViewsMutationVariables>(RecordSocialPostViewsDocument, options);
      }
export type RecordSocialPostViewsMutationHookResult = ReturnType<typeof useRecordSocialPostViewsMutation>;
export type RecordSocialPostViewsMutationResult = Apollo.MutationResult<RecordSocialPostViewsMutation>;
export type RecordSocialPostViewsMutationOptions = Apollo.BaseMutationOptions<RecordSocialPostViewsMutation, RecordSocialPostViewsMutationVariables>;