import { forwardRef, ReactNode } from "react";

import { BaseSocialPostCard } from "@rewards-web/shared/components/social-post-card/base-social-post-card";
import {
  ReactionsSummary,
  ReactionType,
  RedemptionSocialPostDetails,
  RedemptionSocialPostImageVariant,
} from "@rewards-web/shared/graphql-types";
import { useFormatters } from "@rewards-web/shared/modules/formatter";

import redemptionCoinsImageUrl from "./images/coins.png";
import redemptionFireworksImageUrl from "./images/fireworks.png";
import redemptionGiftBoxImageUrl from "./images/gift-box.png";
import redemptionPartyHatImageUrl from "./images/party-hat.png";

export interface RedemptionSocialPostCardProps {
  commonData: {
    logoUrl: string | null;
    publishedByText: string;
    publishedAtText: string;
    postReactions: ReactionsSummary[];
    myReactions: ReactionsSummary[];
    onMyReactionChange: (reactionType: ReactionType, added: boolean) => void;
    showMoreText: string;
    showLessText: string;
  };
  details: {
    __typename?: "RedemptionSocialPostDetails";
  } & Pick<RedemptionSocialPostDetails, "id"> & {
      redemptionImageVariant: RedemptionSocialPostDetails["imageVariant"];
      numUsersRedeemed: number;
      totalPointsRedeemed: number;
    };
}

export const RedemptionSocialPostCard = forwardRef(
  ({ commonData, details }: RedemptionSocialPostCardProps, ref) => {
    const { formatMessage } = useFormatters();

    const overlayText = formatMessage(
      {
        description: "Social post card > redemption post > image overlay text",
        defaultMessage:
          "{total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}}",
      },
      {
        total_points_redeemed: details.totalPointsRedeemed,
      }
    );

    return (
      <BaseSocialPostCard
        ref={ref}
        data={{
          ...commonData,
          tagText: formatMessage({
            description: "Social post card > redemption post > tag",
            defaultMessage: "Redemption",
          }),
          tagColor: "gold",
          messageText: (() => {
            const bold = (nodes: ReactNode[]) => <strong>{nodes}</strong>;
            return formatMessage(
              {
                description: "Social post card > redemption post > message",
                defaultMessage:
                  "🎉 Cheers to our <bold>{num_users_redeemed, number} {num_users_redeemed, plural, one {caregiver} other {caregivers}} for redeeming {total_points_redeemed, number} {total_points_redeemed, plural, one {point} other {points}}</bold> - your well-deserved rewards are ready to go!",
              },
              {
                num_users_redeemed: details.numUsersRedeemed,
                total_points_redeemed: details.totalPointsRedeemed,
                bold,
              }
            );
          })(),
          image: (() => {
            switch (details.redemptionImageVariant) {
              case RedemptionSocialPostImageVariant.GiftBox:
                return {
                  url: redemptionGiftBoxImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "top",
                    textColor: "#EA2276",
                    imageTintColor: "#E6ABC9",
                  },
                };
              case RedemptionSocialPostImageVariant.Coins:
                return {
                  url: redemptionCoinsImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "right",
                    textColor: "#EA2276",
                    imageTintColor: "transparent",
                  },
                };
              case RedemptionSocialPostImageVariant.Fireworks:
                return {
                  url: redemptionFireworksImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "center",
                    imageTintColor: "#4C3970",
                  },
                };
              case RedemptionSocialPostImageVariant.PartyHat:
                return {
                  url: redemptionPartyHatImageUrl,
                  overlayText: {
                    text: overlayText,
                    position: "center",
                    imageTintColor: "#4C3970",
                  },
                };

              default:
                // fall back to no image if we don't recognize it in the frontend
                return null;
            }
          })(),
        }}
      />
    );
  }
);
