/** @jsxImportSource @emotion/react */
import { useParams } from "react-router-dom";

import { Alert } from "@rewards-web/shared/components/alert";
import { GoalTrackName } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";

import { BaseGoalTrackPageProps } from "./base-track-page";
import { EvvGoalTrackPage } from "./evv-goal-track-page";
import { LastMinuteShiftGoalTrackPage } from "./last-minute-shift-track-page";
import { ShareJobGoalTrackPage } from "./share-job-track-page";
import { SurveyGoalTrackPage } from "./survey-goal-track-page";
import { useGoalsForTrackPageQuery } from "./track-goals.generated";
import { WeekendShiftGoalTrackPage } from "./weekend-shift-track-page";

export interface GoalTrackPageProps {}

export function GoalTrackPage() {
  const { track } = useParams<"track">();

  const trackName: GoalTrackName | null = (() => {
    switch (track) {
      case "share-job":
        return GoalTrackName.ShareJobTrack;
      case "survey":
        return GoalTrackName.SurveyTrack;
      case "evv":
        return GoalTrackName.EvvTrack;
      case "last-minute-shift":
        return GoalTrackName.LastMinuteShiftTrack;
      case "weekend-shift":
        return GoalTrackName.WeekendShiftTrack;
      default:
        return null;
    }
  })();

  const unknownTrack = trackName === null;

  const { data, error } = useGoalsForTrackPageQuery({
    ...(unknownTrack
      ? { skip: true }
      : { variables: { trackName }, onError: reportError }),
  });

  if (unknownTrack) {
    return <Alert severity="warning" message="Nothing to see here" />;
  }

  if (error) {
    return (
      <Alert
        severity="error"
        message="An unexpected error occurred. Please try again later."
      />
    );
  }

  const {
    trackData,
    analyticsData,
  }: Pick<BaseGoalTrackPageProps, "trackData" | "analyticsData"> = !data
    ? // consider it still loading if there is no data,
      // so subsequent loads don't re-trigger the loading state
      {
        trackData: { loading: true },
        analyticsData: { trackName, dataStillLoading: true },
      }
    : ((): Pick<BaseGoalTrackPageProps, "trackData" | "analyticsData"> => {
        const {
          availableGoals,
          lockedGoals,
          achievedGoals,
          trackProgress: { pointsEarnedSoFar, totalEarnablePointsForTrack },
        } = data.getMyRewardsUserGoalTrack;

        return {
          trackData: {
            loading: false,
            availableGoals,
            lockedGoals,
            achievedGoals,
            pointsEarnedSoFar,
            totalEarnablePoints: totalEarnablePointsForTrack,
          },
          analyticsData: {
            trackName,
            dataStillLoading: false,
            pointsEarnedSoFar,
            totalEarnablePoints: totalEarnablePointsForTrack,
            numAvailableGoals: availableGoals.length,
            numLockedGoals: lockedGoals.length,
            achievedGoals: achievedGoals.length,
            availableGoalIds: availableGoals.map(({ id }) => id),
          },
        };
      })();

  switch (trackName) {
    case GoalTrackName.EvvTrack:
      return (
        <EvvGoalTrackPage trackData={trackData} analyticsData={analyticsData} />
      );
    case GoalTrackName.ShareJobTrack:
      return (
        <ShareJobGoalTrackPage
          trackData={trackData}
          analyticsData={analyticsData}
        />
      );
    case GoalTrackName.SurveyTrack:
      return (
        <SurveyGoalTrackPage
          trackData={trackData}
          analyticsData={analyticsData}
        />
      );
    case GoalTrackName.LastMinuteShiftTrack:
      return (
        <LastMinuteShiftGoalTrackPage
          trackData={trackData}
          analyticsData={analyticsData}
        />
      );
    case GoalTrackName.WeekendShiftTrack:
      return (
        <WeekendShiftGoalTrackPage
          trackData={trackData}
          analyticsData={analyticsData}
        />
      );
    default:
      return null;
  }
}
