import { NetworkStatus } from "@apollo/client";
import { times } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import { SocialPostCardSkeleton } from "@rewards-web/shared/components/social-post-card/social-post-card-skeleton";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { ErrorPage } from "@rewards-web/shared/pages/error";

import { PageContentContainer } from "../../../shared/page-content-container";
import { ScrollToTopOnMount } from "../../../shared/scroll-to-top-on-mount";
import { SubPageHeader } from "../../../shared/sub-page-header";
import { useInfiniteScrollRef } from "./hooks";
import { useListSocialPostsQuery } from "./list-social-posts.generated";
import { SocialPostCard } from "./social-post-card";
import { useRecordSocialPostViewsMutation } from "./social-post-card/record-social-post-views.generated";

const POSTS_PER_PAGE = 10;

export function SocialPage() {
  const track = useTrack();
  const { formatMessage } = useFormatters();

  const socialPostsQuery = useListSocialPostsQuery({
    variables: { limit: POSTS_PER_PAGE },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError: reportError,
  });

  // Intended to be used as a batched mutation to record views for multiple posts on screen
  const [recordViews] = useRecordSocialPostViewsMutation({
    onError: reportError,
  });

  const lastElementRef = useInfiniteScrollRef({
    hasNextPage: Boolean(socialPostsQuery.data?.posts.nextCursor),
    fetchNextPage: () =>
      socialPostsQuery.fetchMore({
        variables: { cursor: socialPostsQuery.data!.posts.nextCursor },
      }),
  });

  const content = (() => {
    if (socialPostsQuery.error) {
      return <ErrorPage />;
    }

    if (!socialPostsQuery.data) {
      // display skeleton state

      return times(3, (index) => (
        <SocialPostCardSkeleton key={`skeleton:${index}`} />
      ));
    }

    if (socialPostsQuery.data.posts.items.length === 0) {
      return (
        <Alert
          severity="info"
          message={formatMessage({
            defaultMessage: "There are no posts yet. Come back soon!",
            description: "Moments page > feed empty state message",
          })}
        />
      );
    }

    return [
      ...socialPostsQuery.data.posts.items.map((post, index) => {
        return (
          <SocialPostCard
            key={post.id}
            post={post}
            ref={lastElementRef}
            onCardOnScreen={() => {
              if (!post.viewed) {
                try {
                  recordViews({
                    variables: {
                      postIds: [post.id],
                    },
                  });
                } catch (error) {
                  reportError(error);
                }
              }
              track("Viewed social post in feed", {
                postId: post.id,
                postIndexOnScreen: index,
                postType: post.type,
                postPublishedAt: post.publishedAt,
                existingReactions: post.reactionsSummary.reduce<
                  Record<string, number>
                >((acc, reaction) => {
                  acc[reaction.type] = reaction.numReactions;
                  return acc;
                }, {}),
                existingUserReactions: post.myRecordedReactionsSummary.reduce<
                  Record<string, number>
                >((acc, reaction) => {
                  acc[reaction.type] = reaction.numReactions;
                  return acc;
                }, {}),
              });
            }}
          />
        );
      }),

      // if we're loading the next page, display one skeleton component.
      // this allows the user to keep scrolling a bit further, but also
      // not scroll too far (since only one new post may show up)
      socialPostsQuery.networkStatus === NetworkStatus.fetchMore ? (
        <SocialPostCardSkeleton key="skeleton" />
      ) : (
        []
      ),
    ];
  })();

  return (
    <>
      <SubPageHeader
        pageName={formatMessage({
          defaultMessage: "Moments",
          description: "Moments page > page name",
        })}
        analyticsPageName="Moments"
      />
      <PageContentContainer>
        <ScrollToTopOnMount />
        {content}
      </PageContentContainer>
    </>
  );
}
