/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { sortBy } from "lodash";

import { SocialPostTaggedUser } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useAuth } from "../../../../../../../shared/modules/auth";

export function SocialPostCardUserNameList({
  taggedUsers,
}: {
  taggedUsers: SocialPostTaggedUser[];
}) {
  const { userId } = useAuth();

  const orderedUsers = sortBy(
    // only include users who have opted in to appearing in the social feed
    taggedUsers.filter((user) => user.firstNameLastInitial),
    // display logged in user first
    (user) => user.id === userId,
    // then order users by name
    (user) => user.firstNameLastInitial
  );

  return (
    <ul
      css={(theme: AppTheme) => css`
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-top: ${theme.spacing(1)};
      `}
    >
      {/* order users deterministically by ID */}
      {orderedUsers.map((user) => (
        <li key={user.id}>
          <strong>{user.firstNameLastInitial}</strong>
        </li>
      ))}
    </ul>
  );
}
