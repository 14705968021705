/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AppBar } from "@material-ui/core";

import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { AppTheme } from "@rewards-web/shared/style/theme";

import { RewardsAppNavigationTabs } from "../navigation-tabs";

interface ComputerNavigationProps {
  height: number;
  referralsEnabled: boolean;
  drawsEnabled: boolean;
  leaderboardEnabled: boolean;
  socialFeedEnabled: boolean;
  redemptionMethods: RedemptionMethod[];
}

export function ComputerNavigation({
  height,
  referralsEnabled,
  leaderboardEnabled,
  socialFeedEnabled,
  drawsEnabled,
  redemptionMethods,
}: ComputerNavigationProps): JSX.Element {
  return (
    <AppBar
      css={(theme: AppTheme) => css`
        background-color: ${theme.palette.background.paper};
        height: ${height}px;
      `}
      elevation={0}
    >
      <RewardsAppNavigationTabs
        css={css`
          width: 500px;
          margin: 0 auto;
        `}
        referralsEnabled={referralsEnabled}
        leaderboardEnabled={leaderboardEnabled}
        socialFeedEnabled={socialFeedEnabled}
        drawsEnabled={drawsEnabled}
        redemptionMethods={redemptionMethods}
      />
    </AppBar>
  );
}
