/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alpha } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Typography } from "@rewards-web/shared/components/typography";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { AppTheme } from "@rewards-web/shared/style/types";

interface CatalogGiftcardSelectorItemProps {
  iconSrc: string;
  iconAlt: string;
  title: string;
  description: string;
  linkTo: string;
  trackingItemKey: string;
}

export function CatalogGiftcardSelectorItem({
  iconSrc,
  iconAlt,
  title,
  description,
  linkTo,
  trackingItemKey,
}: CatalogGiftcardSelectorItemProps) {
  const track = useTrack();

  return (
    <Link
      to={linkTo}
      onClick={() => {
        track("Clicked redemption selector item", {
          item: trackingItemKey,
        });
      }}
      css={(theme: AppTheme) => css`
        display: block;
        padding: ${theme.spacing(2)};
        border: 1px solid ${theme.palette.grey[400]};
        border-radius: 8px;
        text-decoration: none;
        transition: background-color 0.2s ease, border-color 0.2s ease;
        background-color: ${theme.palette.background.paper};

        &:hover {
          background-color: ${alpha(theme.palette.grey[200], 0.4)};
          border-color: ${theme.palette.grey[600]};
        }
      `}
    >
      <img
        src={iconSrc}
        alt={iconAlt}
        css={(theme: AppTheme) => css`
          width: 64px;
          height: 64px;
        `}
      />
      <Typography
        variant="h5"
        color="text.primary"
        css={(theme: AppTheme) => css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {title}
      </Typography>
      <Typography variant="footnote" color="grey.800">
        {description}
      </Typography>
    </Link>
  );
}
