/** @jsxImportSource @emotion/react */
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import {
  EvvRules,
  EvvRulesClockMethod,
} from "@rewards-web/shared/graphql-types";
import { FormattedMessage } from "@rewards-web/shared/modules/formatter";

export interface EVVTrackPageEVVRulesProps {
  evvRulesLoading: boolean;
  evvRules?: EvvRules;
}

export function EVVTrackPageEVVRules({
  evvRulesLoading,
  evvRules,
}: EVVTrackPageEVVRulesProps) {
  if (evvRulesLoading || !evvRules) {
    return (
      <ul>
        <li>
          <Skeleton width="80%" height={28} />
        </li>
        <li>
          <Skeleton width="75%" height={28} />
        </li>
        <li>
          <Skeleton width="90%" height={28} />
        </li>
        <li>
          <Skeleton width="60%" height={28} />
        </li>
      </ul>
    );
  }

  const {
    minutesLessThanScheduledStart,
    minutesGreaterThanScheduledStart,
    minutesLessThanScheduledEnd,
    minutesGreaterThanScheduledEnd,
    minutesLessThanScheduledDuration,
    minutesGreaterThanScheduledDuration,
    validClockMethods,
  } = evvRules || {};

  // Filter out 'System Auto-Clock' and only keep 'Mobile App' and 'IVR'
  const filteredClockMethods = validClockMethods?.filter(
    (method) => method !== EvvRulesClockMethod.SystemAutoClock
  );

  return (
    <ul>
      {/* Clock-In Timeliness */}
      {typeof minutesLessThanScheduledStart === "number" &&
        typeof minutesGreaterThanScheduledStart === "number" &&
        minutesLessThanScheduledStart === minutesGreaterThanScheduledStart && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-in equal bounds"
              defaultMessage="<strong>Clock in</strong> within {minutes, number} {minutes, plural, one {minute} other {minutes}} of the scheduled start time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes: minutesGreaterThanScheduledStart,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledStart === "number" &&
        typeof minutesGreaterThanScheduledStart === "number" &&
        minutesLessThanScheduledStart !== minutesGreaterThanScheduledStart && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-in different bounds"
              defaultMessage="<strong>Clock in</strong> no more than {minutes_less, number} {minutes_less, plural, one {minute} other {minutes}} before the scheduled start time or more than {minutes_greater, number} {minutes_greater, plural, one {minute} other {minutes}} after the scheduled start time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_less: minutesLessThanScheduledStart,
                minutes_greater: minutesGreaterThanScheduledStart,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledStart !== "number" &&
        typeof minutesGreaterThanScheduledStart === "number" && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-in after only"
              defaultMessage="<strong>Clock in</strong> no more than {minutes_greater, number} {minutes_greater, plural, one {minute} other {minutes}} after the scheduled start time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_greater: minutesGreaterThanScheduledStart,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledStart === "number" &&
        typeof minutesGreaterThanScheduledStart !== "number" && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-in before only"
              defaultMessage="<strong>Clock in</strong> no more than {minutes_less, number} {minutes_less, plural, one {minute} other {minutes}} before the scheduled start time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_less: minutesLessThanScheduledStart,
              }}
            />
          </li>
        )}

      {/* Clock-Out Timeliness */}
      {typeof minutesLessThanScheduledEnd === "number" &&
        typeof minutesGreaterThanScheduledEnd === "number" &&
        minutesLessThanScheduledEnd === minutesGreaterThanScheduledEnd && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-out equal bounds"
              defaultMessage="<strong>Clock out</strong> within {minutes, number} {minutes, plural, one {minute} other {minutes}} of the scheduled end time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes: minutesGreaterThanScheduledEnd,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledEnd === "number" &&
        typeof minutesGreaterThanScheduledEnd === "number" &&
        minutesLessThanScheduledEnd !== minutesGreaterThanScheduledEnd && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-out different bounds"
              defaultMessage="<strong>Clock out</strong> no more than {minutes_less, number} {minutes_less, plural, one {minute} other {minutes}} before the scheduled end time or more than {minutes_greater, number} {minutes_greater, plural, one {minute} other {minutes}} after the scheduled end time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_less: minutesLessThanScheduledEnd,
                minutes_greater: minutesGreaterThanScheduledEnd,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledEnd !== "number" &&
        typeof minutesGreaterThanScheduledEnd === "number" && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-out after only"
              defaultMessage="<strong>Clock out</strong> no more than {minutes_greater, number} {minutes_greater, plural, one {minute} other {minutes}} after the scheduled end time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_greater: minutesGreaterThanScheduledEnd,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledEnd === "number" &&
        typeof minutesGreaterThanScheduledEnd !== "number" && (
          <li>
            <FormattedMessage
              description="EVV rules > clock-out before only"
              defaultMessage="<strong>Clock out</strong> no more than {minutes_less, number} {minutes_less, plural, one {minute} other {minutes}} before the scheduled end time."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_less: minutesLessThanScheduledEnd,
              }}
            />
          </li>
        )}

      {/* Visit Duration */}
      {typeof minutesLessThanScheduledDuration === "number" &&
        typeof minutesGreaterThanScheduledDuration === "number" &&
        minutesLessThanScheduledDuration === 0 &&
        minutesGreaterThanScheduledDuration === 0 && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration exact match"
              defaultMessage="<strong>Visit duration</strong> must exactly match the scheduled visit duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledDuration === "number" &&
        typeof minutesGreaterThanScheduledDuration === "number" &&
        minutesLessThanScheduledDuration ===
          minutesGreaterThanScheduledDuration &&
        minutesLessThanScheduledDuration !== 0 && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration equal bounds"
              defaultMessage="<strong>Visit duration</strong> must be within {minutes, number} {minutes, plural, one {minute} other {minutes}} of the scheduled visit duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes: minutesGreaterThanScheduledDuration,
              }}
            />
          </li>
        )}

      {/* Case: Lower bound is 0 */}
      {minutesLessThanScheduledDuration === 0 &&
        minutesGreaterThanScheduledDuration !== 0 && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration no less than scheduled duration"
              defaultMessage="<strong>Visit duration</strong> cannot be less than the scheduled visit duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
              }}
            />
          </li>
        )}

      {/* Case: Upper bound is 0 */}
      {minutesGreaterThanScheduledDuration === 0 &&
        minutesLessThanScheduledDuration !== 0 && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration no more than scheduled duration"
              defaultMessage="<strong>Visit duration</strong> cannot exceed the scheduled visit duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
              }}
            />
          </li>
        )}

      {typeof minutesLessThanScheduledDuration === "number" &&
        typeof minutesGreaterThanScheduledDuration === "number" &&
        minutesLessThanScheduledDuration !==
          minutesGreaterThanScheduledDuration &&
        minutesLessThanScheduledDuration !== 0 &&
        minutesGreaterThanScheduledDuration !== 0 && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration different bounds"
              defaultMessage="<strong>Visit duration</strong> cannot be less than {minutes_less, number} {minutes_less, plural, one {minute} other {minutes}} of the scheduled visit duration and cannot exceed {minutes_greater, number} {minutes_greater, plural, one {minute} other {minutes}} beyond the scheduled duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_less: minutesLessThanScheduledDuration,
                minutes_greater: minutesGreaterThanScheduledDuration,
              }}
            />
          </li>
        )}

      {/* Case: Visit can only be longer */}
      {typeof minutesLessThanScheduledDuration !== "number" &&
        typeof minutesGreaterThanScheduledDuration === "number" && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration longer only"
              defaultMessage="<strong>Visit duration</strong> cannot exceed {minutes_greater, number} {minutes_greater, plural, one {minute} other {minutes}} beyond the scheduled visit duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_greater: minutesGreaterThanScheduledDuration,
              }}
            />
          </li>
        )}

      {/* Case: Visit can only be shorter */}
      {typeof minutesLessThanScheduledDuration === "number" &&
        typeof minutesGreaterThanScheduledDuration !== "number" && (
          <li>
            <FormattedMessage
              description="EVV rules > visit duration shorter only"
              defaultMessage="<strong>Visit duration</strong> cannot be less than {minutes_less, number} {minutes_less, plural, one {minute} other {minutes}} of the scheduled visit duration."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
                minutes_less: minutesLessThanScheduledDuration,
              }}
            />
          </li>
        )}

      {/* Valid Clock Methods */}
      {filteredClockMethods?.includes(EvvRulesClockMethod.MobileApp) &&
        filteredClockMethods?.length === 1 && (
          <li>
            <FormattedMessage
              description="EVV rules > clock method > mobile app"
              defaultMessage="<strong>Clock in and out</strong> using the Mobile App."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
              }}
            />
          </li>
        )}

      {filteredClockMethods?.includes(EvvRulesClockMethod.Ivr) &&
        filteredClockMethods?.length === 1 && (
          <li>
            <FormattedMessage
              description="EVV rules > clock method ivr"
              defaultMessage="<strong>Clock in and out</strong> using the client’s home phone."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
              }}
            />
          </li>
        )}

      {filteredClockMethods?.includes(EvvRulesClockMethod.MobileApp) &&
        filteredClockMethods?.includes(EvvRulesClockMethod.Ivr) && (
          <li>
            <FormattedMessage
              description="EVV rules > clock method mobile app or ivr"
              defaultMessage="<strong>Clock in and out</strong> using the Mobile App or client’s home phone."
              values={{
                strong: (nodes) => <strong>{nodes}</strong>,
              }}
            />
          </li>
        )}
    </ul>
  );
}
