import * as Types from '@rewards-web/shared/graphql-types';

import { SocialPostCardFragmentFragment } from './social-post-card-fragment.generated';
import { gql } from '@apollo/client';
import { SocialPostCardFragmentFragmentDoc } from './social-post-card-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RemoveSocialPostReactionMutationVariables = Types.Exact<{
  postId: Types.Scalars['ID'];
  reactionType: Types.ReactionType;
}>;


export type RemoveSocialPostReactionMutation = (
  { __typename?: 'Mutation' }
  & { removeReaction: (
    { __typename?: 'SocialPost' }
    & SocialPostCardFragmentFragment
  ) }
);


export const RemoveSocialPostReactionDocument = gql`
    mutation RemoveSocialPostReaction($postId: ID!, $reactionType: ReactionType!) {
  removeReaction(postId: $postId, reactionType: $reactionType) {
    ...SocialPostCardFragment
  }
}
    ${SocialPostCardFragmentFragmentDoc}`;
export type RemoveSocialPostReactionMutationFn = Apollo.MutationFunction<RemoveSocialPostReactionMutation, RemoveSocialPostReactionMutationVariables>;

/**
 * __useRemoveSocialPostReactionMutation__
 *
 * To run a mutation, you first call `useRemoveSocialPostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSocialPostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSocialPostReactionMutation, { data, loading, error }] = useRemoveSocialPostReactionMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useRemoveSocialPostReactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSocialPostReactionMutation, RemoveSocialPostReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSocialPostReactionMutation, RemoveSocialPostReactionMutationVariables>(RemoveSocialPostReactionDocument, options);
      }
export type RemoveSocialPostReactionMutationHookResult = ReturnType<typeof useRemoveSocialPostReactionMutation>;
export type RemoveSocialPostReactionMutationResult = Apollo.MutationResult<RemoveSocialPostReactionMutation>;
export type RemoveSocialPostReactionMutationOptions = Apollo.BaseMutationOptions<RemoveSocialPostReactionMutation, RemoveSocialPostReactionMutationVariables>;